import { Spin } from 'antd';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Pages from './pages';
import Farmer from './farmer';
import FarmHistory from './farmHistory';
import Analytics from './analytics';
import Soil from './soil';
import Farm from './farm';
import Blog from './Blog';
import Farmhub from './farmhub';
import Stock from './stock';
import Finance from './finance';
import Batch from './batch';
import withAdminLayout from '../../layout/withAdminLayout';

const Admin = React.memo(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Pages />} />
        <Route path="pages/*" element={<Pages />} />
        <Route path="farmhub/*" element={<Farmhub />} />
        <Route path="farmer/*" element={<Farmer />} />
        <Route path="farm/*" element={<Farm />} />
        <Route path="stock/*" element={<Stock />} />
        <Route path="farmHistory/*" element={<FarmHistory />} />
        <Route path="soil/*" element={<Soil />} />
        <Route path="blog/*" element={<Blog />} />
        <Route path="analytics/*" element={<Analytics />} />
        <Route path="finances/*" element={<Finance />} />
        <Route path="batch/*" element={<Batch />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
