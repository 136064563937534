/* eslint-disable */
import React from 'react';
import { Routes, Route } from 'react-router-dom';

const ViewBatch = React.lazy(() => import('../../container/batch/viewBatch'));
const CreateBatch = React.lazy(() => import('../../container/batch/createBatch'));
const UpdateBatch = React.lazy(() => import('../../container/batch/updateBatch'));
const ViewBatchDetails = React.lazy(() => import('../../container/batch/batchDetails'));
const AddStockToBatch = React.lazy(() => import('../../container/batch/addStock'));

function BatchRoute() {
  return (
    <Routes>
      <Route path="list" element={<ViewBatch />} />
      <Route path="add" element={<CreateBatch />} />
      <Route path="update/:batchId" element={<UpdateBatch />} />
      <Route path="view/:batchId" element={<ViewBatchDetails />} />
      <Route path="add-stock/:batchId" element={<AddStockToBatch />} />
    </Routes>
  );
}

export default BatchRoute;
