import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const AddStock = lazy(() => import('../../container/stock/addStock'));
const ViewStock = lazy(() => import('../../container/stock/viewStock'));
const EditStock = lazy(() => import('../../container/stock/editStock'));
const DetailStock = lazy(() => import('../../container/stock/viewStock/detailStock'));

function FarmRoute() {
  return (
    <Routes>
      <Route path="add/:NationalId" element={<AddStock />} />
      <Route path="list" element={<ViewStock />} />
      <Route path="edit/:stockId" element={<EditStock />} />
      <Route path="view/:stockId" element={<DetailStock />} />
    </Routes>
  );
}

export default FarmRoute;
